import React from 'react';
import { UserContext } from './UserContext';
import { Button, Row, Col } from 'antd';
import LogoutButton from '../components/LogoutButton';
import { Link } from 'react-router-dom';
import { userSession, getUserData, getPerson } from '../auth';

const Nav = () => {
  if (!userSession.isUserSignedIn()) {
    return null;
  }
  const userData = getUserData()
  return (
    <Row type="flex" justify="end">
      <Col>
        <Link to="/">
          <Button type="link">
            Home
          </Button>
        </Link>
      </Col>
      <Col>
        <Link to="/about">
          <Button type="link">
            About
          </Button>
        </Link>
      </Col>
      {userData &&
        <>
        <Col>
          <Link to="/dashboard">
            <Button type="link">
              {userData.username || userData.decentralizedID}
            </Button>
          </Link>
        </Col>
        <Col>
          <LogoutButton />
        </Col>
        </>
      }
    </Row>
  );
}

export default Nav;
