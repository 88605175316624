import React from 'react';
import { Button } from 'antd';
import { UserContext } from './UserContext';
import { userSession } from '../auth';

export const LogoutButton = () => {
    return (
      <UserContext.Consumer>
        {context => context && (
          <Button type="default" onClick={() => {
            userSession.signUserOut(window.location.origin);
          }}>
            Logout
          </Button>
        )}
      </UserContext.Consumer>
    );
};

export default LogoutButton;
