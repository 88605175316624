import React from 'react';
import { Row, Button } from 'antd';
import logo from '../logo.png';
// import logo from '../trakkin-logo.png';

const Branding = () => {
  return (
    <Row type="flex" justify="start">
    <div className="logo-title">
      <img src="/logo.png" alt="trakkin.me" />
      <h1><Button type="link" href="/">trakkin.me</Button></h1>
      {/*<img src={logo} alt="trakkin.me" />*/}
    </div>
    </Row>
  );
}

export default Branding;
