import React from 'react';
import '../App.css';
import { Row, Col, Icon } from 'antd';
import { UserContext } from '../components/UserContext';

const Dashboard = () => {
  return (
    <Row type="flex" justify="center" className="dashboard-container">
      <Col span={12}>
        <Row>
          <Col>
            <p>You've successfully logged in! Now if you haven't installed the <a href="https://chrome.google.com/webstore/detail/trakkinme/mminmjmebgdbfdljmffabngnkghapobg">Chrome Extension</a>, do that now. If you have, then you are all set! Open the trakkin extension popup to see how many requests are getting the trakkin RFP (request for payment).</p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Dashboard;
