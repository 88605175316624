import { UserContextProviderProps, ContextValue } from '../interfaces';
import React, { useEffect } from 'react';
import { reducer, initialState, LOGIN_USER } from './Reducers';
import { userSession, getUserData, getPerson } from '../auth';
import Auth from './Auth';
const auth = new Auth();

const UserContext = React.createContext<ContextValue | null>(null);
const UserContextProvider = (props: UserContextProviderProps) => {
  const [userState, dispatch] = React.useReducer(reducer, initialState.user);

  const initialAuth = () => {
    return auth.handleAuthentication().then(() => {
      if (!userSession.isUserSignedIn()) {
        return null;
      }
      const user = getUserData()
      dispatch({
        type: LOGIN_USER,
        user,
      });
    });
  };

  useEffect(() => {
    initialAuth();
  }, []);

  return (
    <UserContext.Provider
      value={{
        user: { ...userState },
        userSignIn: auth.signIn,
        userSignOut: auth.signOut,
        userAuthentication: initialAuth
      }}>
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
