import React from 'react';
import { Button } from 'antd';
import BSIcon from './blockstack-icon';
import { UserContext } from './UserContext';
import { authenticate } from '../auth';

export default function LoginButton () {
  return (
    <UserContext.Consumer>
      {context => context && (
        <Button type="default" shape="round" size="large" onClick={() => { authenticate() }}>
          <BSIcon style={{ color: '#3700ff', transform: 'translateY(3px)' }} />
          Login with Blockstack
        </Button>
      )}
    </UserContext.Consumer>
  );
}
