// import { UserSession, makeAuthRequest, AppConfig } from '@stacks/auth';
import { User, AuthInterface } from '../interfaces';
import ReactGA from "react-ga";

// const appDomain = 'https://trakkin.me'
// const appConfig = new AppConfig(['store_write', 'publish_data', 'email'], appDomain);
export default class Auth implements AuthInterface {
  // @ts-ignore
  // userSession = new UserSession({ appConfig });
  profile: User = { name: '', username: '' };
  // transitKey = this.userSession.generateAndStoreTransitKey();
  redirectUri = '/dashboard'
  manifestUri = '/manifest.json'
  constructor() {
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
  }

  signIn() {
    // @ts-ignore
    // const authRequest = this.userSession.makeAuthRequest(
    //   this.transitKey,
    //   this.redirectUri,
    //   this.manifestUri
    // );
    // console.log('fooo', authRequest)
  }

  signOut() {
    // this.userSession.signUserOut(window.location.origin);
    ReactGA.event({
      category: 'User',
      action: 'Logged out',
      label: 'Blockstack'
    });
  }

  getProfile() {
    const { username } = this.profile;
    return { username };
  }

  handleAuthentication() {
    // const session = this.userSession;
    return new Promise((resolve, reject) => {
      // if (session && session.isUserSignedIn()) {
      //   ReactGA.event({
      //     category: 'User',
      //     action: 'IsSignedInTest',
      //     label: 'Blockstack'
      //   });

      //   const userData = session.loadUserData();
      //   const { profile } = userData;
      //   profile.username = userData.username;
      //   this.profile = profile;

      //   // session.getFile('me.json', { decrypt: false }).then((content) => {
      //   //   console.log(content);
      //   // });
      //   //
      //   // session.putFile('me.json', JSON.stringify({ foo: 'bar' }), { encrypt: true }).then((content) => {
      //   //   console.log(content);
      //   // });
      //   // @ts-ignore
      //   resolve();
      // } else if (session && session.isSignInPending()) {
      //   session.handlePendingSignIn()
      //     .then((userData) => {
      //       if (!userData.username) return reject(new Error('no username'));

      //       const { profile } = userData;
      //       profile.username = userData.username;
      //       this.profile = profile;

      //       console.log('tracking login event')
      //       ReactGA.event({
      //         category: 'User',
      //         action: 'Logged into Account',
      //         label: 'Blockstack'
      //       });

      //       // @ts-ignore
      //       resolve();
      //     })
      // } else {
        // @ts-ignore
        resolve();
      // }
    });
  }
}
