import React, { Component } from 'react';
import { Button, Modal, Row } from 'antd';
import '../App.css';
import '../AppPricing.css';
import { RouteComponentProps } from "react-router-dom";
import { CreatedCharge, CreatedChargeError } from '../interfaces';

const opennode = require('opennode');
opennode.setCredentials(process.env.REACT_APP_ON_API_KEY, process.env.REACT_APP_ON_API_ENV);

type RouteParams = {
  hostname: string;
};

interface Props extends RouteComponentProps<RouteParams> { }

interface State {
  hostname: string;
  visible: boolean;
  confirmLoading: boolean;
  payreq: string;
}

class Pricing extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hostname: props.match.params.hostname,
      visible: false,
      confirmLoading: false,
      payreq: '',
    };
    this.generateInvoice = this.generateInvoice.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOk = this.handleOk.bind(this);
  }
  render() {
    const { visible, confirmLoading, payreq } = this.state;

    return (
      <>
        <Row className="pricing-content">
          <h2>{this.state.hostname}</h2>
          <p>
            Hi there! You're probably on this page because you saw some funky stuff in your analytics, right?
          </p>
          <p>
            So here's the deal. People are tired of freely giving their data over to companies. As a business owner, you know how valuable data can be. Trakkin is a way to enable businesses like you to give some of that value to your users!
          </p>
          <p>
            Luckily we've made it incredibly easy for you to give some of that value to your users! Using Bitcoin and the Lightning network, you can automatically re-enable users who are currently obscuring their analytics data to you.
          </p>
          <p>
            Simply pick one of the pricing options below, pay it, and Boom! you see the correct data, and users start earning Bitcoin!
          </p>
          <div className="pricing-options">
            <div className="option">
              <h3>Basic</h3>
              <p className="price"><small>$</small>50</p>
              <p>
                Pay 100 Satoshi per event <br/>
                Receive <strong>~4,000</strong> events
              </p>
              <Button type="default" shape="round" size="large" onClick={() => { this.generateInvoice(1) }}>
                Get Invoice
              </Button>
              <p className="price-note">
                Prices change with the price movements of bitcoin
              </p>
            </div>
            <div className="option">
              <h3>Scale</h3>
              <p className="price"><small>$</small>200</p>
              <p>
                Pay 10 Satoshi per event <br/>
                Receive <strong>~150,000</strong> events
              </p>
              <Button type="primary" shape="round" size="large" onClick={() => { this.generateInvoice(200) }}>
                Get Invoice
              </Button>
              <p className="price-note">
                Prices change with the price movements of bitcoin
              </p>
            </div>
            <div className="option">
              <h3>Enterprise</h3>
              <p className="price"><small>$</small>1,000</p>
              <p>
                Pay 1 Satoshi per event <br/>
                Receive <strong>~8,400,000</strong> events
              </p>
              <p></p>
              <Button type="default" shape="round" size="large" onClick={() => { this.generateInvoice(999) }}>
                Get Invoice
              </Button>
              <p className="price-note">
                Prices change with the price movements of bitcoin
              </p>
            </div>
          </div>
        </Row>
        <Modal
          title="Title"
          visible={visible}
          centered
          onOk={this.handleOk}
          confirmLoading={confirmLoading}
          onCancel={this.handleCancel}
        >
          <p>{payreq}</p>
        </Modal>
      </>
    );
  }
  async generateInvoice(price: number) {
    const orderId = 1;
    // var query = `mutation addCustomer($order_id) {
    //   createMessage(input: $order_id) {
    //     id
    //   }
    // }`;
    const charge = {
      description: 'trakkin.me charge',
      amount: price,
      currency: 'USD',
      order_id: this.state.hostname,
      customer_name: this.state.hostname,
      customer_email: 'dylanbathurst@gmail.com',
      callback_url: `${process.env.REACT_APP_API_URL}/query`,
      success_url: `https://trakkin.me/order/${orderId}`,
      auto_settle: false
    };

    opennode.createCharge(charge)
    	.then((charge: CreatedCharge) => {
        const { payreq } = charge.lightning_invoice;
        this.setState({ visible: true, payreq });
    	})
    	.catch((error: CreatedChargeError) => {
    		console.error(`${error.status} | ${error.message}`);
    	});
  }
  handleOk() {
    this.setState({
      confirmLoading: true,
    });
    setTimeout(() => {
      this.setState({
        visible: false,
        confirmLoading: false,
      });
    }, 2000);
  }
  handleCancel() {
    this.setState({ visible: false });
  }
}

export default Pricing;
