import { User, ActionProps } from '../interfaces';

export const LOGIN_USER = 'LOGIN_USER';

const initialState = {
  user: {
    username: ''
  }
};

const reducer = (state: User, action: ActionProps) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        username: action.user.username
      };
    default:
      return state;
  }
};

export { reducer, initialState };
