import React from 'react';
import { Icon } from 'antd';

const blockstackLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" fill="currentColor" viewBox="0 0 160 160">
    <g transform="translate(0.000000,160.000000) scale(0.100000,-0.100000)" stroke="none">
      <path d="M132 1579 c-53 -26 -109 -91 -122 -140 -7 -24 -9 -265 -8 -663 l3 -624 30 -43 c17 -24 50 -57 74 -74 l43 -30 648 0 648 0 43 30 c24 17 57 50 74 74 l30 43 0 648 0 648 -30 43 c-17 24 -50 57 -74 74 l-43 30 -636 3 c-633 2 -637 2 -680 -19z m511 -428 c24 -24 31 -40 34 -83 5 -63 -9 -94 -56 -125 -24 -16 -49 -23 -83 -23 -41 0 -54 5 -84 33 -62 56 -64 145 -5 201 55 52 140 51 194 -3z m452 39 c8 0 30 -14 50 -31 64 -56 66 -145 5 -204 -38 -36 -125 -47 -165 -21 -41 27 -65 75 -65 129 0 40 5 53 33 83 31 34 86 57 115 48 8 -2 20 -4 27 -4z m-484 -525 c43 -23 69 -71 69 -127 0 -119 -144 -176 -232 -91 -49 47 -56 131 -16 181 41 49 122 66 179 37z m540 -22 c76 -76 43 -203 -61 -233 -82 -23 -170 42 -170 126 0 93 57 148 148 141 43 -3 59 -10 83 -34z"/>
    </g>
  </svg>
);

interface BSIconProps {
  style: object
}

const BSIcon = (props: BSIconProps) => <Icon component={blockstackLogo} {...props} />;

export default BSIcon;
