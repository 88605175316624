import '../../node_modules/antd/dist/antd.css';
import React from 'react';
import '../App.css';
import '../Landing.css';
import BTCIcon from '../components/bitcoin-icon';
import { Icon, Row, Col, Tooltip } from 'antd';
import LoginButton from '../components/LoginButton';

const Landing = () => {
  return (
    <>
      <div className="hero">
        <div className="hero-cta">
          <h2>It's your data, <br />Get paid for it</h2>
          <p>Anonymize yourself on the internet while requesting bitcoin for your data. Install our extension on <a href="https://chrome.google.com/webstore/detail/trakkinme/mminmjmebgdbfdljmffabngnkghapobg" rel="noopener noreferrer" target="_blank">Chrome</a>, <a href="https://addons.mozilla.org/en-US/firefox/addon/trakkin/" rel="noopener noreferrer" target="_blank">Firefox</a>, <a href="https://addons.opera.com/en/extensions/details/trakkin/" rel="noopener noreferrer" target="_blank">Opera</a> <Tooltip placement="topLeft" title={'Currently only for Opera Development or Beta releases'}><Icon type="info-circle" style={{ fontSize: '13px' }} theme="twoTone" /></Tooltip>, or <a href="https://chrome.google.com/webstore/detail/trakkinme/mminmjmebgdbfdljmffabngnkghapobg" rel="noopener noreferrer" target="_blank">Brave</a>, and then login below.</p>
          <LoginButton />
          <a className="producthunt-promo" type="link" href="https://www.producthunt.com/posts/trakkin-me?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-trakkin-me" target="_blank" rel="noopener noreferrer"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=168010&theme=light" alt="trakkin.me - Earn bitcoin while blocking trackers like GA and Mixpanel | Product Hunt Embed" width="250px" height="54px" /></a>
        </div>
        <div className="hero-visual">
          <img src="/hero-rotate.png" alt="" />
        </div>
        <div className="ext-promo">
          <img src="/ext-screenshot.png" alt="" />
        </div>
      </div>
      <div className="hero-2">
        <div className="slant">
        </div>
        <div className="hero-2-content">
          <Row type="flex" justify="space-around">
            <Col span={6}>
              <h3><Icon type="stop" /> Fool Tracking Software</h3>
              <p>Websites will see your pageviews as a payment request from trakkin with no PII attached</p>
            </Col>
            <Col span={6}>
              <h3><BTCIcon /> Earn Bitcoin</h3>
              <p>Once a company pays the Lightning invoice, your GA, Mixpanel, etc. tracking will resume automatically</p>
            </Col>
            <Col span={6}>
              <h3><Icon type="cloud-download" /> Cash Out Any Time</h3>
              <p>Funds are held in a custodial wallet, cash out whenever you like</p>
            </Col>
          </Row>
        </div>
      </div>
      <div className="hero-3">
        <div className="example">
          <div className="description">
            <h3>What Google Analytics Customers See</h3>
            <p>
              Companies using services like Google Analytics won't see your PII data like email address, IP, click, and other events.
            </p>
          </div>
          <a href="/ga-screenshot.png">
            <img src="/ga-screenshot.png" alt="" />
          </a>
        </div>
      </div>
      <div className="hero-4">
        <div className="example">
          <a href="/mixpanel-screenshot.png">
            <img src="/mixpanel-screenshot.png" alt="" />
          </a>
          <div className="description">
            <h3>What Mixpanel Customers See</h3>
            <p>
              Companies using services like Mixpanel won't see your PII data like email address, IP, click, and other events.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Landing;
