import React from 'react';
import '../App.css';
import { Row, Col } from 'antd';

const About = () => {
  return (
    <Row type="flex" justify="center" className="about-container">
      <Col span={12}>
        <h2>Welcome to trakkin</h2>
        <p>
          trakkin.me & the associated <a href="https://chrome.google.com/webstore/detail/trakkinme/mminmjmebgdbfdljmffabngnkghapobg" title="trakkin.me chrome extension">Chrome extension</a> were built by <a href="https://twitter.com/dylanbathurst">Dylan Bathurst</a> as part of the <a href="https://devpost.com/software/trakkin-me">Blockstack: Can't be evil</a> hack-a-thon. trakkin was built to help people take back control of the thousands of data points they produce every day and don't even realize. trakkin puts the earning power of data back into the hands of the users that create it.
        </p>
        <h3>Goals for trakkin</h3>
        <ul>
          <li>Enable everyday people to earn money (Bitcoin) from the data they produce</li>
          <li>Change the perception that companies have where acquiring more data is always better (Data lakes <span role="img" aria-label="Thumbs down emoji">👎</span>)</li>
          <li>Show people how earning and paying with Bitcoin is incredible!</li>
        </ul>
      </Col>
    </Row>
  );
}

export default About;
