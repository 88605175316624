import React, { useEffect, useState } from 'react';
import { UserContext } from './components/UserContext';
import './App.css';
import Nav from './components/Nav'
import Branding from './components/Branding'
import Landing from './pages/Landing'
import Dashboard from './pages/Dashboard'
import About from './pages/About'
import Pricing from './pages/Pricing'
import { withTracker } from './components/withTracker';
import { Layout, Row, Col } from 'antd';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { userSession } from './auth';
import { UserData } from '@stacks/auth';

const { Header, Content } = Layout;

const App = () => {
  const [userData, setUserData] = useState<UserData | {}>({})
  useEffect(() => {
    if (userSession.isSignInPending()) {
      userSession.handlePendingSignIn().then((user) => {
        window.history.replaceState({}, document.title, "/")
        setUserData(user)
        let bsSess = window.localStorage.getItem('blockstack-session')
        console.log('ewwww', bsSess)
      });
    } else if (userSession.isUserSignedIn()) {
      const data = userSession.loadUserData()
      setUserData(data);
      console.log('dwwww', data)
      if (!data.username) {
        let bsSess = window.localStorage.getItem('blockstack-session')
        if (bsSess) {
          const sessObj = JSON.parse(bsSess)
          sessObj.userData.username = sessObj.userData.identityAddress
          window.localStorage.setItem('blockstack-session', JSON.stringify(sessObj))
        }
      }
    }
    // @ts-ignore
  }, [userData.username])
  return (
      <UserContext.Consumer>
      {context => context && (
        <Layout className="App">
          <Router>
            <Header>
              <Row type="flex" justify="space-between" className="App-header">
                <Col span={8}>
                  <Branding />
                </Col>
                <Col span={12}>
                  <Nav />
                </Col>
              </Row>
            </Header>
            <Content>
              <Switch>
                <Route exact path="/" component={withTracker(Landing)} />
                <Route exact path="/about" component={withTracker(About)} />
                <Route exact path="/dashboard" component={withTracker(Dashboard)} />
                <Route exact path="/co/:hostname" component={withTracker(Pricing)} />
                <Route exact path="/company/:hostname" component={withTracker(Pricing)} />
              </Switch>
            </Content>
          </Router>
        </Layout>
      )}
      </UserContext.Consumer>
  );
}

// App.contextType = SessionContext;

export default App;
